import React from "react"
import { graphql } from "gatsby"
import { useShoppingCart } from "use-shopping-cart"
import { loadStripe } from "@stripe/stripe-js"

import Layout from "components/Layout"
import Seo from "components/SEO"
import AddedToCart from "components/AddedToCart"

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)
  }
  return stripePromise
}

const SingleProductTemplate = ({ data, pageContext }) => {
  const { redirectToCheckout } = useShoppingCart()
  const [productPrice, setProductPrice] = React.useState({})
  const [openModal, setOpenModal] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const handlerSingleItemCheckOut = async event => {
    setLoading(true)
    event.preventDefault()
    try {
      const request = await fetch("/.netlify/functions/stripe", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify([
          {
            price: productPrice.id,
            quantity: 1,
          },
        ]),
      })

      const response = await request.json()

      await redirectToCheckout(response.sessionId)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const { addItem } = useShoppingCart()

  React.useEffect(() => {
    const element = document.getElementById("select-price")

    const productPrice = element.options[element.selectedIndex]?.dataset

    setProductPrice({ ...productPrice })
  }, [])

  const handlePriceChange = e => {
    const productPrice = e.target.options[e.target.selectedIndex]?.dataset
    setProductPrice({ ...productPrice })
  }

  const { product, prices } = data

  return (
    <React.Fragment>
      <Seo
        title={`${product.name} | Mandatory Insects`}
        description={`${product.description}`}
      />
      <Layout>
        <>
          <div className="flex justify-center items-center w-full">
            <div className="w-full md:w-10/12 lg:w-8/12 xl:w-full flex justify-center flex-col items-center py-16 px-4 md:px-6 xl:px-20 2xl:container 2xl:mx-auto">
              <div className="flex justify-start items-start flex-col space-y-3">
                <p className="text-4xl font-semibold leading-9 text-gray-800">
                  Shop Online | {product.name}
                </p>
              </div>

              <div className="flex justify-center content-center  items-center xl:items-center flex-col xl:flex-row space-y-8 xl:space-y-0 xl:space-x-8">
                <div className="w-full flex justify-center items-center content-center flex-col-reverse xl:flex-row gap-y-4 xl:gap-y-0 xl:space-x-8">
                  <div>
                    <img
                      className="hidden xl:block w-full max-w-[400px]"
                      src={product.images[0]}
                      alt={product.name}
                      width="300"
                    />
                    <img
                      className="xl:hidden w-full"
                      src={product.images[0]}
                      alt={product.name}
                    />
                  </div>
                </div>
                <div className="w-full xl:w-7/12 flex justify-start items-start flex-col">
                  <p className="text-xl lg:text-2xl font-semibold leading-5 xl:leading-6 text-gray-800 ">
                    {product.name}
                  </p>
                  <div className="text-lg md:text-xl font-medium leading-5 text-gray-600 mt-3">
                    <div className="relative inline-flex mt-4">
                      <svg
                        className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 412 232"
                      >
                        <path
                          d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z"
                          fill="#648299"
                          fillRule="nonzero"
                        />
                      </svg>

                      <select
                        disabled={loading || prices.nodes.length === 0}
                        id="select-price"
                        onChange={event => {
                          handlePriceChange(event)
                        }}
                        className=" border border-gray-300 rounded-full text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none "
                        style={{ width: 350 }}
                      >
                        {prices.nodes.length !== 0 ? (
                          <React.Fragment>
                            {prices.nodes.map((price, index) => {
                              let selectText = `$${Math.abs(
                                price.unit_amount / 100
                              ).toFixed(2)} - ${price.nickname} ${price.metadata.available === "false"
                                ? " - No Available"
                                : ""
                                }`

                              return (
                                <option
                                  key={index}
                                  value={price.id}
                                  data-name={`${product.name} (${price.nickname})`}
                                  data-description={price.nickname}
                                  data-id={price.id}
                                  data-price={price.unit_amount}
                                  data-currency={price.currency}
                                  data-image={product.images[0]}
                                  disabled={
                                    price.metadata.available === "false" ||
                                    product.metadata.available === "false"
                                  }
                                >
                                  {selectText}
                                </option>
                              )
                            })}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <option value="">None currently available</option>
                          </React.Fragment>
                        )}
                      </select>
                    </div>
                  </div>
                  <p className="text-base leading-normal text-gray-600 mt-5 ">
                    {product.description}
                  </p>

                  <div className="mt-10 w-full flex-row md:flex justify-center items-start space-x-4 ">
                    {product.metadata.available === "true" &&
                      prices.nodes.length !== 0 ? (
                      <React.Fragment>
                        <div className="flex flex-col xl:flex-row gap-2 mb-2">
                          <button
                            onClick={e => handlerSingleItemCheckOut(e)}
                            disabled={loading}
                            className=" disabled:opacity-50 w-full md:w-60 flex items-center justify-center px-4 py-4 border border-transparent text-base font-medium rounded-md text-white bg-lime-600 hover:bg-lime-700 md:py-4 md:text-lg"
                          >
                            {loading ? "Loading Checkout..." : "Buy Now"}
                          </button>

                          <button
                            onClick={() => {
                              addItem(productPrice)
                              setOpenModal(true)
                            }}
                            disabled={loading}
                            className=" disabled:opacity-50 w-full md:w-60 flex items-center justify-center px-4 py-4 border border-transparent text-base font-medium rounded-md text-lime-700 bg-lime-100 hover:bg-lime-200 md:py-4 md:text-lg"
                          >
                            Add To Cart
                          </button>
                        </div>
                      </React.Fragment>
                    ) : (
                      <div>
                        <button
                          disabled
                          className="disabled:opacity-50 w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-lime-700 bg-lime-100 hover:bg-lime-200 md:py-4 md:text-lg md:px-10"
                        >
                          OUT OF STOCK
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddedToCart
            productName={product.name}
            openModal={openModal}
            closeModal={() => setOpenModal(false)}
          />
        </>
      </Layout>
    </React.Fragment>
  )
}

export default SingleProductTemplate

export const pageQuery = graphql`
  ## Query for Product Prices Stripe Data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $productId is processed via gatsby-node.js
  ## query name must be unique to this file

  query getAllStripePrice($productId: String!) {
    prices: allStripePrice(
      filter: { active: { eq: true }, product: { id: { eq: $productId } } }
    ) {
      nodes {
        unit_amount
        id
        currency
        metadata {
          available
          limit
          quantity
          insect_size
        }
        nickname
      }
    }

    product: stripeProduct(id: { eq: $productId }) {
      description
      images
      metadata {
        available
      }
      name
      object
    }
  }
`
